<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/recordPoint' }">点检记录</el-breadcrumb-item>
          <el-breadcrumb-item>异常转检修</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="dform" label-width="100px">
          <div class="form-title">点检信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="点检代号：">{{ dform.taskInfo.taskCode }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="巡视点名称：">{{ dform.taskInfo.patrolName }}</el-form-item>
            </el-col>
          </el-row>
          <div class="form-device-info">
            <div class="form-title">设备信息</div>
          </div>
          <div style="margin-bottom:20px;">
            <div v-if="devFrom.patrolDeviceId">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="设备别名：">{{devFrom.alias}}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="设备识别号：">{{devFrom.ein}}</el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="设施位置：">{{dform.taskInfo.facilityName}}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="设备类型：">{{devFrom.deviceTypeName}}</el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="设备名称：">{{devFrom.deviceName}}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="规格参数：">{{devFrom.specification}}</el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="所属部门：">{{devFrom.departmentName}}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="投入使用日期：" label-width="130px">
                  {{ devFrom.applyTime ? dayjs(devFrom.applyTime).format('YYYY-MM-DD'):'-' }}
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="form-title">点检内容</div>
          <el-form-item
            v-for="item in dform.checkContentInfo.contents"
            :key="item"
            label="检查内容："
            prop="name">
            {{ item }}
          </el-form-item>
          <div class="form-title">问题文字描述</div>
          <el-form-item label-width="20px">{{dform.description.description}}</el-form-item>
          <div class="form-title">点检工作图片</div>
          <div class="img-box">
            <el-image 
              class="images"
              v-for="(item,index) in dform.files.fileUrls"
              :key="index"
              :src="item"
              :preview-src-list="[item]">
            </el-image>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="点检人：">{{dform.submitter}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提交时间：">
                  {{ dform.completeTime ? dayjs(dform.completeTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title">
            基本信息
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="检修代号" prop="name">
                <el-input v-model="form.name" placeholder="请输入检修代号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="来源：">
                  <el-input v-model="form.source" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">
            检修人员配置<span style="color: red"> *</span>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="检修人员">
                <div @click="addStaff()" class="staffBox">
                  <div v-if="staffList.length>0">
                    <el-tag v-for="staff in staffList" :key="staff.id" style="margin-right: 4px;">
                      {{ staff.name }}
                    </el-tag>
                  </div>
                  <div v-else style="color: #909399;text-align: center;">请选择检修人员</div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">
            问题文字描述<span style="color: red"> *</span>
          </div>
          <el-form-item label-width="20px" prop="desc">
            <el-input v-model="form.desc" type="textarea" :rows="2" style="width:700px" placeholder="请输入问题描述"></el-input>
          </el-form-item>
          <div class="form-title">
            检修方案（审核人员填）<span style="color: red"> *</span>
          </div>
          <el-form-item label-width="20px" prop="plan">
            <el-input v-model="form.plan" type="textarea" :rows="2" style="width:700px" placeholder="请输入检修方案"></el-input>
          </el-form-item>
          <div class="bottom-btn">
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <el-drawer
        title=""
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
      size="900px">
        <div class="drawer-title">选择巡检员</div>
        <div class="drawer-content" v-if="drawer">
          <div class="table-tree">
            <div class="tree">
              <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
              <el-tree
                :data="treeData"
                @node-click="treeRowClick"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                ref="tree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            
            <div class="table">
              <el-table :data="tableData" style="width: 100%" height="700">
                <el-table-column width="40">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sex" label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
                </el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="name" label="岗位">
                  <template slot-scope="scope">
                    <div
                      v-for="item in scope.row.positionInfoList"
                      :key="item.positionId"
                    >{{item.positionName}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" @click="drawer=false">取消</el-button>
          <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      loading: false,
      form: {
        name: '',
        source: '点检',
        aliasObj: {},
        desc: '',
        plan: ''
      },
      row: {},
      dform: {
        checkContentInfo: {},
        description: {},
        deviceBaseInfo: {},
        files: {},
        taskInfo: {}
      },
      devFrom: {},
      deviceRadio: '',
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        desc:[{ required: true, message: '必填项', trigger: 'blur' }],
        plan:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      props: {
        value: 'id',
        label: 'name',
        children: 'childTree'
      },
      options: [],
      value: [],
      facility: [], // 设施列表
      patrolList: [],
      staffList: [], // 检修人员列表
      index: 1,
      // 添加设备初始变量
      deviceDrawer: false,
      tableDataCp: [],
      tableSelectList: {},
      deviceTableData: [],
      facilityId: 0, // 设施Id
      facilityName: '', // 设施位置
      patrolId: 0, // 巡视点Id
      patrolName: '', // 巡视点名称
      patrolNumber: '', // 巡视点编号
      // 增加配置初始变量
      drawer: false,
      treeData: [],
      tableData: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      checkedStaffList: [],
      staffListID: [],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    }
  },
  methods: {
    dayjs,
    // 选择巡检员
    addStaff() {
      this.loadTableData(this.treeData[0].id)
      this.staffListID = []
      this.staffList.forEach(item => {
        this.staffListID.push(item.id)
      })
      this.checkedStaffList = [].concat(this.staffList)
      this.drawer = true
    },
    // 获取部门结构树
    loadTreeData() {
      this.$ajax.post('departmentResponsibleChild').then(res => {
        this.treeData = res.data
      })
    },
    // 获取部门人员
    loadTableData(id) {
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        res.data.forEach(item => {
          let inIs = this.staffListID.find(function(obj) {
            return obj == item.id
          })
          if (!inIs) {
            item.check = false
          }else {
            item.check = true
          }
        })
        this.tableData = res.data
      })
    },
    // 选择部门结构
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    // 勾选巡检员
    checked(row) {
      if (!this.staffListID.includes(row.id)) {
        this.checkedStaffList.push(row)
        this.staffListID.push(row.id)
      } else {
        this.checkedStaffList.forEach((item, index) => {
          if (item.id == row.id) {
            this.checkedStaffList.splice(index, 1)
            this.$delete(this.staffListID, index)
            console.log(this.staffListID)
          }
        })
      }
    },
    // 选择巡检员点击确定
    drawerOK() {
      this.drawer = false
      this.staffList = [].concat(this.checkedStaffList)
      this.staffListID = [].concat(this.staffListID)
    },
    // 提交任务
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let fObj =  this.devFrom
          let form = {
            abnormalTaskInfo: {
              taskDeviceId: this.row.taskDeviceId
            },
            baseInfo: {
              order: this.form.name,
              source: 2
            },
            deviceInfo: {
              alias: fObj.alias, // 设备别名
              applyTime: fObj.applyTime, // 投入使用日期
              departmentId: fObj.departmentId, // 部门Id
              departmentName: fObj.departmentName, // 所属部门
              archivesId: fObj.archivesId, // 设备Id
              deviceName: fObj.deviceName, // 设备名称
              deviceTypeId: fObj.deviceTypeId,
              deviceTypeName: fObj.deviceTypeName, // 分类
              ein: fObj.ein, // 设备识别号
              facilityId: fObj.facilityId, // 设施Id
              facilityName: fObj.facilityName, // 设施位置
              patrolDeviceId: fObj.patrolDeviceId, // 巡视点设备Id
              patrolId: fObj.patrolId, // 巡视点Id
              patrolName: fObj.patrolName, // 巡视点名称
              patrolNumber: fObj.patrolNumber, // 巡视点编号
              specification: fObj.specification // 规格参数
            },
            elementInfo: {
              description: this.form.desc,
              plan: this.form.plan,
              staffInfos: []
            }
          } 
          this.staffList.forEach((item)=>{
            form.elementInfo.staffInfos.push({
              staffId: item.id,
              staffName: item.name
            })
          })
          if (form.elementInfo.staffInfos.length == 0) {
            this.$message.error("人员配置不能为空！");
            return
          }
          this.loading = true
          this.$ajax.post("turnOverhaul", form).then((res) => {
            this.loading = false
            this.$message.success("成功");
            this.$router.push("/overhaulTask");
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    loadCycleRecord() {
      let form = {
        operation: 1,
        patrolDeviceId: this.row.patrolDeviceId,
        taskDeviceId: this.row.taskDeviceId
      }
      this.$ajax.post("recordRecondition", form).then((res) => {
        this.dform = res.data
        this.$ajax.post("rDeviceDetail", { patrolDeviceId: res.data.deviceBaseInfo.patrolDeviceId }).then((v) => {
          this.devFrom = v.data
        });
      })
    }
  },
  mounted() {
    this.row = this.$route.params.row;
    console.log(this.row)
    this.loadCycleRecord()
    this.loadTreeData()
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1000px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 390px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #e7e9ee;
}
.checkbox {
  margin-bottom: 20px;
  padding-left: 40px;
}
.form-device-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>